//
// Scaffolding
// --------------------------------------------------

// Reset fonts for relevant elements
input,
button,
select,
textarea {
    .placeholder();
}

// Links

a {
    color: @link-color;
    text-decoration: none;

    &:hover,
    &:focus {
        color: @link-hover-color;
        text-decoration: @link-hover-decoration;
    }

    &:focus {
        .tab-focus();
    }
}

// Horizontal rules

hr {
    margin-top: @line-height-computed;
    margin-bottom: @line-height-computed;
    border: 0;
    border-top: 1px solid @hr-border;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role='button'] {
    cursor: pointer;
}
