@import '../design';
.avatar {
    border-radius: 50%;
    background: no-repeat center;
    background-size: cover;
    background-image: url('assets/1.jpg');
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 10px;
    flex-shrink: 0;
    // Styles for letter in avatar
    color: @white;
    text-align: center;
    font-size: @font-size-UI18;
    line-height: 1.66;
    &.hide-letter {
        &:hover {
            color: transparent;
            &:after {
                color: @white;
            }
        }
    }
    &.disabled {
        filter: grayscale(1);
        opacity: 0.5;
    }
    user-select: none;
    cursor: default;
    .title {
        display: none;
    }
}
