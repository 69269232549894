/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: ' (' attr(href) ')';
  }
  abbr[title]:after {
    content: ' (' attr(title) ')';
  }
  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
}
input::-moz-placeholder,
button::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
  color: #647491;
  opacity: 1;
}
input:-ms-input-placeholder,
button:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #647491;
}
input::-webkit-input-placeholder,
button::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #647491;
}
a {
  color: #0566ff;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #0047b7;
  text-decoration: none;
}
a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
hr {
  margin-top: 18px;
  margin-bottom: 18px;
  border: 0;
  border-top: 1px solid #3c485b;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
[role='button'] {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #dee3e8;
}
h1:before,
h2:before,
h3:before,
h4:before,
h5:before,
h6:before {
  margin-right: 10px;
  color: #0566ff;
}
h1,
h2,
h3 {
  margin-top: 18px;
  margin-bottom: 9px;
}
h1 small,
h2 small,
h3 small,
h1 .small,
h2 .small,
h3 .small {
  font-size: 65%;
}
h4,
h5,
h6 {
  margin-top: 9px;
  margin-bottom: 9px;
}
h4 small,
h5 small,
h6 small,
h4 .small,
h5 .small,
h6 .small {
  font-size: 75%;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
p {
  margin: 0 0 9px;
}
small,
.small {
  font-size: 85%;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-danger {
  color: #e81332;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #b90f28;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 9px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
table {
  background-color: transparent;
}
th {
  text-align: left;
  font-weight: 400;
  color: #647491;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 6px;
  border-collapse: collapse;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  min-width: 55px;
  vertical-align: middle;
  padding: 8px 16px;
  line-height: 1.28571429;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th {
  height: 48px;
}
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  height: 56px;
}
.table > thead > tr:first-of-type td,
.table > tbody > tr:first-of-type td,
.table > tfoot > tr:first-of-type td {
  border-top: none;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table-responsive {
  overflow-x: auto;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}
label {
  display: inline-block;
  max-width: 100%;
  font-weight: 400;
}
input[type='search'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type='radio'],
input[type='checkbox'] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
input[type='file'] {
  display: block;
}
input[type='range'] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.form-control {
  display: block;
  width: 100%;
  height: 32px;
  padding: 0px 14px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.28571429;
  color: #080f1a;
  background-color: #fff;
  background-image: none;
  border: 2px solid #d3dbe5;
  border-radius: 8px;
}
.form-control::-moz-placeholder {
  color: #647491;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #647491;
}
.form-control::-webkit-input-placeholder {
  color: #647491;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eff2f6;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
textarea.form-control {
  height: auto;
  padding: 8px 16px;
}
input[type='search'] {
  -webkit-appearance: none;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='date'].form-control,
  input[type='time'].form-control,
  input[type='datetime-local'].form-control,
  input[type='month'].form-control {
    line-height: 32px;
  }
  input[type='date'].input-sm,
  input[type='time'].input-sm,
  input[type='datetime-local'].input-sm,
  input[type='month'].input-sm {
    line-height: 30px;
  }
}
.form-group {
  margin-bottom: 16px;
}
.form-group .form-control {
  margin-bottom: 0;
}
.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radio label,
.checkbox label {
  min-height: 18px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.radio input[type='radio'],
.checkbox input[type='checkbox'] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}
.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}
input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='checkbox'].disabled,
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'] {
  cursor: not-allowed;
}
.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #26477b;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  min-width: 64px;
  padding: 6px 14px;
  font-size: 14px;
  line-height: 1.28571429;
  border-radius: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}
.btn:hover,
.btn:focus,
.btn.focus {
  color: #333;
  text-decoration: none;
}
.btn:active,
.btn.active {
  outline: 0;
  background-image: none;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #acb8cb;
  border-color: transparent;
}
.btn.disabled:not(.btn-loading):not(.btn-link),
.btn[disabled]:not(.btn-loading):not(.btn-link),
fieldset[disabled] .btn:not(.btn-loading):not(.btn-link) {
  background-color: #eff2f6;
}
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}
.btn-default {
  color: #333;
  background-color: rgba(136, 148, 171, 0);
  border-color: #d1d9e0;
}
.btn-default:focus,
.btn-default.focus {
  color: #333;
  box-shadow: 0 0 0 0.2em rgba(136, 148, 171, 0.5);
}
.btn-default:hover,
.btn-default.hover {
  color: #333;
  background-color: #eff2f6;
  border-color: #acb8cb;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #dbe2eb;
  border-color: #99a8bf;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-default .badge {
  color: rgba(136, 148, 171, 0);
  background-color: #333;
}
.btn-default:focus,
.btn-default.focus {
  box-shadow: 0 0 0 0.2em rgba(179, 192, 203, 0.3);
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: rgba(136, 148, 171, 0.16);
  border-color: #aeb4c2;
}
.btn-default .badge {
  color: #fff;
}
.btn-primary {
  color: #fff;
  background-color: #0566ff;
  border-color: #0566ff;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  box-shadow: 0 0 0 0.2em rgba(5, 102, 255, 0.5);
}
.btn-primary:hover,
.btn-primary.hover {
  color: #fff;
  background-color: #0049bd;
  border-color: #0049bd;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #003d9e;
  border-color: #003d9e;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-primary .badge {
  color: #0566ff;
  background-color: #fff;
}
.btn-success {
  color: #fff;
  background-color: #34b857;
  border-color: #34b857;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  box-shadow: 0 0 0 0.2em rgba(52, 184, 87, 0.5);
}
.btn-success:hover,
.btn-success.hover {
  color: #fff;
  background-color: #25833e;
  border-color: #25833e;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #1e6b33;
  border-color: #1e6b33;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-success .badge {
  color: #34b857;
  background-color: #fff;
}
.btn-warning {
  color: #3a2300;
  background-color: #ff9f05;
  border-color: #ff9f05;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #3a2300;
  box-shadow: 0 0 0 0.2em rgba(255, 159, 5, 0.5);
}
.btn-warning:hover,
.btn-warning.hover {
  color: #3a2300;
  background-color: #d48200;
  border-color: #d48200;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #3a2300;
  background-color: #b56f00;
  border-color: #b56f00;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-warning .badge {
  color: #ff9f05;
  background-color: #3a2300;
}
.btn-danger {
  color: #fff;
  background-color: #e81332;
  border-color: #e81332;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  box-shadow: 0 0 0 0.2em rgba(232, 19, 50, 0.5);
}
.btn-danger:hover,
.btn-danger.hover {
  color: #fff;
  background-color: #a50d24;
  border-color: #a50d24;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #890b1e;
  border-color: #890b1e;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-danger .badge {
  color: #e81332;
  background-color: #fff;
}
.btn-purple {
  color: #fff;
  background-color: #501cd8;
  border-color: #501cd8;
}
.btn-purple:focus,
.btn-purple.focus {
  color: #fff;
  box-shadow: 0 0 0 0.2em rgba(80, 28, 216, 0.5);
}
.btn-purple:hover,
.btn-purple.hover {
  color: #fff;
  background-color: #331289;
  border-color: #331289;
}
.btn-purple:active,
.btn-purple.active,
.open > .dropdown-toggle.btn-purple {
  color: #fff;
  background-color: #290e6e;
  border-color: #290e6e;
}
.btn-purple:active,
.btn-purple.active,
.open > .dropdown-toggle.btn-purple {
  background-image: none;
}
.btn-purple.disabled:hover,
.btn-purple[disabled]:hover,
fieldset[disabled] .btn-purple:hover,
.btn-purple.disabled:focus,
.btn-purple[disabled]:focus,
fieldset[disabled] .btn-purple:focus,
.btn-purple.disabled.focus,
.btn-purple[disabled].focus,
fieldset[disabled] .btn-purple.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-purple .badge {
  color: #501cd8;
  background-color: #fff;
}
.btn-sapphire {
  color: #fff;
  background-color: #303f9f;
  border-color: #303f9f;
}
.btn-sapphire:focus,
.btn-sapphire.focus {
  color: #fff;
  box-shadow: 0 0 0 0.2em rgba(48, 63, 159, 0.5);
}
.btn-sapphire:hover,
.btn-sapphire.hover {
  color: #fff;
  background-color: #212c6e;
  border-color: #212c6e;
}
.btn-sapphire:active,
.btn-sapphire.active,
.open > .dropdown-toggle.btn-sapphire {
  color: #fff;
  background-color: #1a2356;
  border-color: #1a2356;
}
.btn-sapphire:active,
.btn-sapphire.active,
.open > .dropdown-toggle.btn-sapphire {
  background-image: none;
}
.btn-sapphire.disabled:hover,
.btn-sapphire[disabled]:hover,
fieldset[disabled] .btn-sapphire:hover,
.btn-sapphire.disabled:focus,
.btn-sapphire[disabled]:focus,
fieldset[disabled] .btn-sapphire:focus,
.btn-sapphire.disabled.focus,
.btn-sapphire[disabled].focus,
fieldset[disabled] .btn-sapphire.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-sapphire .badge {
  color: #303f9f;
  background-color: #fff;
}
.btn-pink {
  color: #fff;
  background-color: #f43c7b;
  border-color: #f43c7b;
}
.btn-pink:focus,
.btn-pink.focus {
  color: #fff;
  box-shadow: 0 0 0 0.2em rgba(244, 60, 123, 0.5);
}
.btn-pink:hover,
.btn-pink.hover {
  color: #fff;
  background-color: #dc0c53;
  border-color: #dc0c53;
}
.btn-pink:active,
.btn-pink.active,
.open > .dropdown-toggle.btn-pink {
  color: #fff;
  background-color: #bf0a48;
  border-color: #bf0a48;
}
.btn-pink:active,
.btn-pink.active,
.open > .dropdown-toggle.btn-pink {
  background-image: none;
}
.btn-pink.disabled:hover,
.btn-pink[disabled]:hover,
fieldset[disabled] .btn-pink:hover,
.btn-pink.disabled:focus,
.btn-pink[disabled]:focus,
fieldset[disabled] .btn-pink:focus,
.btn-pink.disabled.focus,
.btn-pink[disabled].focus,
fieldset[disabled] .btn-pink.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-pink .badge {
  color: #f43c7b;
  background-color: #fff;
}
.btn-trout {
  color: #fff;
  background-color: #647491;
  border-color: #647491;
}
.btn-trout:focus,
.btn-trout.focus {
  color: #fff;
  box-shadow: 0 0 0 0.2em rgba(100, 116, 145, 0.5);
}
.btn-trout:hover,
.btn-trout.hover {
  color: #fff;
  background-color: #3c485b;
  border-color: #3c485b;
}
.btn-trout:active,
.btn-trout.active,
.open > .dropdown-toggle.btn-trout {
  color: #fff;
  background-color: #303949;
  border-color: #303949;
}
.btn-trout:active,
.btn-trout.active,
.open > .dropdown-toggle.btn-trout {
  background-image: none;
}
.btn-trout.disabled:hover,
.btn-trout[disabled]:hover,
fieldset[disabled] .btn-trout:hover,
.btn-trout.disabled:focus,
.btn-trout[disabled]:focus,
fieldset[disabled] .btn-trout:focus,
.btn-trout.disabled.focus,
.btn-trout[disabled].focus,
fieldset[disabled] .btn-trout.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-trout .badge {
  color: #647491;
  background-color: #fff;
}
.btn-sky {
  color: #fff;
  background-color: #00a9ff;
  border-color: #00a9ff;
}
.btn-sky:focus,
.btn-sky.focus {
  color: #fff;
  box-shadow: 0 0 0 0.2em rgba(0, 169, 255, 0.5);
}
.btn-sky:hover,
.btn-sky.hover {
  color: #fff;
  background-color: #0086ca;
  border-color: #0086ca;
}
.btn-sky:active,
.btn-sky.active,
.open > .dropdown-toggle.btn-sky {
  color: #fff;
  background-color: #0072ab;
  border-color: #0072ab;
}
.btn-sky:active,
.btn-sky.active,
.open > .dropdown-toggle.btn-sky {
  background-image: none;
}
.btn-sky.disabled:hover,
.btn-sky[disabled]:hover,
fieldset[disabled] .btn-sky:hover,
.btn-sky.disabled:focus,
.btn-sky[disabled]:focus,
fieldset[disabled] .btn-sky:focus,
.btn-sky.disabled.focus,
.btn-sky[disabled].focus,
fieldset[disabled] .btn-sky.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-sky .badge {
  color: #00a9ff;
  background-color: #fff;
}
.btn-shade {
  color: #0049bd;
  background-color: #dce9ff;
  border-color: #dce9ff;
}
.btn-shade:focus,
.btn-shade.focus {
  color: #0049bd;
  box-shadow: 0 0 0 0.2em rgba(220, 233, 255, 0.5);
}
.btn-shade:hover,
.btn-shade.hover {
  color: #0049bd;
  background-color: #9ac1ff;
  border-color: #9ac1ff;
}
.btn-shade:active,
.btn-shade.active,
.open > .dropdown-toggle.btn-shade {
  color: #0049bd;
  background-color: #7baeff;
  border-color: #7baeff;
}
.btn-shade:active,
.btn-shade.active,
.open > .dropdown-toggle.btn-shade {
  background-image: none;
}
.btn-shade.disabled:hover,
.btn-shade[disabled]:hover,
fieldset[disabled] .btn-shade:hover,
.btn-shade.disabled:focus,
.btn-shade[disabled]:focus,
fieldset[disabled] .btn-shade:focus,
.btn-shade.disabled.focus,
.btn-shade[disabled].focus,
fieldset[disabled] .btn-shade.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-shade .badge {
  color: #dce9ff;
  background-color: #0049bd;
}
.btn-white {
  color: #0566ff;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-white:focus,
.btn-white.focus {
  color: #0566ff;
  box-shadow: 0 0 0 0.2em rgba(255, 255, 255, 0.5);
}
.btn-white:hover,
.btn-white.hover {
  color: #0566ff;
  background-color: #eff2f6;
  border-color: #eff2f6;
}
.btn-white:active,
.btn-white.active,
.open > .dropdown-toggle.btn-white {
  color: #0566ff;
  background-color: #dbe2eb;
  border-color: #dbe2eb;
}
.btn-white:active,
.btn-white.active,
.open > .dropdown-toggle.btn-white {
  background-image: none;
}
.btn-white.disabled:hover,
.btn-white[disabled]:hover,
fieldset[disabled] .btn-white:hover,
.btn-white.disabled:focus,
.btn-white[disabled]:focus,
fieldset[disabled] .btn-white:focus,
.btn-white.disabled.focus,
.btn-white[disabled].focus,
fieldset[disabled] .btn-white.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-white .badge {
  color: #ffffff;
  background-color: #0566ff;
}
.btn-orange {
  color: #fff;
  background-color: #ff9f05;
  border-color: #ff9f05;
}
.btn-orange:focus,
.btn-orange.focus {
  color: #fff;
  box-shadow: 0 0 0 0.2em rgba(255, 159, 5, 0.5);
}
.btn-orange:hover,
.btn-orange.hover {
  color: #fff;
  background-color: #d48200;
  border-color: #d48200;
}
.btn-orange:active,
.btn-orange.active,
.open > .dropdown-toggle.btn-orange {
  color: #fff;
  background-color: #b56f00;
  border-color: #b56f00;
}
.btn-orange:active,
.btn-orange.active,
.open > .dropdown-toggle.btn-orange {
  background-image: none;
}
.btn-orange.disabled:hover,
.btn-orange[disabled]:hover,
fieldset[disabled] .btn-orange:hover,
.btn-orange.disabled:focus,
.btn-orange[disabled]:focus,
fieldset[disabled] .btn-orange:focus,
.btn-orange.disabled.focus,
.btn-orange[disabled].focus,
fieldset[disabled] .btn-orange.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-orange .badge {
  color: #ff9f05;
  background-color: #fff;
}
.btn-disabled {
  color: #647491;
  background-color: #eceef1;
  border-color: #eceef1;
}
.btn-disabled:focus,
.btn-disabled.focus {
  color: #647491;
  box-shadow: 0 0 0 0.2em rgba(236, 238, 241, 0.5);
}
.btn-disabled:hover,
.btn-disabled.hover {
  color: #647491;
  background-color: #eceef1;
  border-color: #eceef1;
}
.btn-disabled:active,
.btn-disabled.active,
.open > .dropdown-toggle.btn-disabled {
  color: #647491;
  background-color: #dadee4;
  border-color: #dadee4;
}
.btn-disabled:active,
.btn-disabled.active,
.open > .dropdown-toggle.btn-disabled {
  background-image: none;
}
.btn-disabled.disabled:hover,
.btn-disabled[disabled]:hover,
fieldset[disabled] .btn-disabled:hover,
.btn-disabled.disabled:focus,
.btn-disabled[disabled]:focus,
fieldset[disabled] .btn-disabled:focus,
.btn-disabled.disabled.focus,
.btn-disabled[disabled].focus,
fieldset[disabled] .btn-disabled.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-disabled .badge {
  color: #eceef1;
  background-color: #647491;
}
.btn-upgrade {
  color: #080f1a;
  background-color: #64ed80;
  border-color: #64ed80;
}
.btn-upgrade:focus,
.btn-upgrade.focus {
  color: #080f1a;
  box-shadow: 0 0 0 0.2em rgba(100, 237, 128, 0.5);
}
.btn-upgrade:hover,
.btn-upgrade.hover {
  color: #080f1a;
  background-color: #31e756;
  border-color: #31e756;
}
.btn-upgrade:active,
.btn-upgrade.active,
.open > .dropdown-toggle.btn-upgrade {
  color: #080f1a;
  background-color: #1adf42;
  border-color: #1adf42;
}
.btn-upgrade:active,
.btn-upgrade.active,
.open > .dropdown-toggle.btn-upgrade {
  background-image: none;
}
.btn-upgrade.disabled:hover,
.btn-upgrade[disabled]:hover,
fieldset[disabled] .btn-upgrade:hover,
.btn-upgrade.disabled:focus,
.btn-upgrade[disabled]:focus,
fieldset[disabled] .btn-upgrade:focus,
.btn-upgrade.disabled.focus,
.btn-upgrade[disabled].focus,
fieldset[disabled] .btn-upgrade.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-upgrade .badge {
  color: #64ed80;
  background-color: #080f1a;
}
.btn-outline-default {
  color: #eff2f6;
  background-color: transparent;
  border-color: #d1d9e0;
  color: #333;
}
.btn-outline-default:focus,
.btn-outline-default.focus {
  color: #eff2f6;
  box-shadow: 0 0 0 0.2em rgba(209, 217, 224, 0.5);
}
.btn-outline-default:hover,
.btn-outline-default.hover {
  color: #333;
  background-color: rgba(136, 148, 171, 0.08);
  border-color: rgba(136, 148, 171, 0.56);
}
.btn-outline-default:active,
.btn-outline-default.active,
.open > .dropdown-toggle.btn-outline-default {
  color: #333;
  background-color: rgba(118, 132, 158, 0.08);
  border-color: rgba(118, 132, 158, 0.56);
}
.btn-outline-default:active,
.btn-outline-default.active,
.open > .dropdown-toggle.btn-outline-default {
  background-image: none;
}
.btn-outline-default.disabled:hover,
.btn-outline-default[disabled]:hover,
fieldset[disabled] .btn-outline-default:hover,
.btn-outline-default.disabled:focus,
.btn-outline-default[disabled]:focus,
fieldset[disabled] .btn-outline-default:focus,
.btn-outline-default.disabled.focus,
.btn-outline-default[disabled].focus,
fieldset[disabled] .btn-outline-default.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-outline-default .badge {
  color: #eff2f6;
  background-color: #333;
}
.btn-outline-default:focus,
.btn-outline-default.focus {
  color: #333;
}
.btn-outline-primary {
  color: #0566ff;
  background-color: transparent;
  border-color: #0566ff;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  color: #0566ff;
  box-shadow: 0 0 0 0.2em rgba(5, 102, 255, 0.5);
}
.btn-outline-primary:hover,
.btn-outline-primary.hover {
  color: #fff;
  background-color: #0049bd;
  border-color: #0049bd;
}
.btn-outline-primary:active,
.btn-outline-primary.active,
.open > .dropdown-toggle.btn-outline-primary {
  color: #fff;
  background-color: #003d9e;
  border-color: #003d9e;
}
.btn-outline-primary:active,
.btn-outline-primary.active,
.open > .dropdown-toggle.btn-outline-primary {
  background-image: none;
}
.btn-outline-primary.disabled:hover,
.btn-outline-primary[disabled]:hover,
fieldset[disabled] .btn-outline-primary:hover,
.btn-outline-primary.disabled:focus,
.btn-outline-primary[disabled]:focus,
fieldset[disabled] .btn-outline-primary:focus,
.btn-outline-primary.disabled.focus,
.btn-outline-primary[disabled].focus,
fieldset[disabled] .btn-outline-primary.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-outline-primary .badge {
  color: #0566ff;
  background-color: #fff;
}
.btn-outline-success {
  color: #34b857;
  background-color: transparent;
  border-color: #34b857;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  color: #34b857;
  box-shadow: 0 0 0 0.2em rgba(52, 184, 87, 0.5);
}
.btn-outline-success:hover,
.btn-outline-success.hover {
  color: #fff;
  background-color: #25833e;
  border-color: #25833e;
}
.btn-outline-success:active,
.btn-outline-success.active,
.open > .dropdown-toggle.btn-outline-success {
  color: #fff;
  background-color: #1e6b33;
  border-color: #1e6b33;
}
.btn-outline-success:active,
.btn-outline-success.active,
.open > .dropdown-toggle.btn-outline-success {
  background-image: none;
}
.btn-outline-success.disabled:hover,
.btn-outline-success[disabled]:hover,
fieldset[disabled] .btn-outline-success:hover,
.btn-outline-success.disabled:focus,
.btn-outline-success[disabled]:focus,
fieldset[disabled] .btn-outline-success:focus,
.btn-outline-success.disabled.focus,
.btn-outline-success[disabled].focus,
fieldset[disabled] .btn-outline-success.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-outline-success .badge {
  color: #34b857;
  background-color: #fff;
}
.btn-outline-warning {
  color: #ff9f05;
  background-color: transparent;
  border-color: #ff9f05;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  color: #ff9f05;
  box-shadow: 0 0 0 0.2em rgba(255, 159, 5, 0.5);
}
.btn-outline-warning:hover,
.btn-outline-warning.hover {
  color: #3a2300;
  background-color: #d48200;
  border-color: #d48200;
}
.btn-outline-warning:active,
.btn-outline-warning.active,
.open > .dropdown-toggle.btn-outline-warning {
  color: #3a2300;
  background-color: #b56f00;
  border-color: #b56f00;
}
.btn-outline-warning:active,
.btn-outline-warning.active,
.open > .dropdown-toggle.btn-outline-warning {
  background-image: none;
}
.btn-outline-warning.disabled:hover,
.btn-outline-warning[disabled]:hover,
fieldset[disabled] .btn-outline-warning:hover,
.btn-outline-warning.disabled:focus,
.btn-outline-warning[disabled]:focus,
fieldset[disabled] .btn-outline-warning:focus,
.btn-outline-warning.disabled.focus,
.btn-outline-warning[disabled].focus,
fieldset[disabled] .btn-outline-warning.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-outline-warning .badge {
  color: #ff9f05;
  background-color: #3a2300;
}
.btn-outline-danger {
  color: #e81332;
  background-color: transparent;
  border-color: #e81332;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  color: #e81332;
  box-shadow: 0 0 0 0.2em rgba(232, 19, 50, 0.5);
}
.btn-outline-danger:hover,
.btn-outline-danger.hover {
  color: #fff;
  background-color: #a50d24;
  border-color: #a50d24;
}
.btn-outline-danger:active,
.btn-outline-danger.active,
.open > .dropdown-toggle.btn-outline-danger {
  color: #fff;
  background-color: #890b1e;
  border-color: #890b1e;
}
.btn-outline-danger:active,
.btn-outline-danger.active,
.open > .dropdown-toggle.btn-outline-danger {
  background-image: none;
}
.btn-outline-danger.disabled:hover,
.btn-outline-danger[disabled]:hover,
fieldset[disabled] .btn-outline-danger:hover,
.btn-outline-danger.disabled:focus,
.btn-outline-danger[disabled]:focus,
fieldset[disabled] .btn-outline-danger:focus,
.btn-outline-danger.disabled.focus,
.btn-outline-danger[disabled].focus,
fieldset[disabled] .btn-outline-danger.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-outline-danger .badge {
  color: #e81332;
  background-color: #fff;
}
.btn-outline-purple {
  color: #501cd8;
  background-color: transparent;
  border-color: #501cd8;
}
.btn-outline-purple:focus,
.btn-outline-purple.focus {
  color: #501cd8;
  box-shadow: 0 0 0 0.2em rgba(80, 28, 216, 0.5);
}
.btn-outline-purple:hover,
.btn-outline-purple.hover {
  color: #fff;
  background-color: #331289;
  border-color: #331289;
}
.btn-outline-purple:active,
.btn-outline-purple.active,
.open > .dropdown-toggle.btn-outline-purple {
  color: #fff;
  background-color: #290e6e;
  border-color: #290e6e;
}
.btn-outline-purple:active,
.btn-outline-purple.active,
.open > .dropdown-toggle.btn-outline-purple {
  background-image: none;
}
.btn-outline-purple.disabled:hover,
.btn-outline-purple[disabled]:hover,
fieldset[disabled] .btn-outline-purple:hover,
.btn-outline-purple.disabled:focus,
.btn-outline-purple[disabled]:focus,
fieldset[disabled] .btn-outline-purple:focus,
.btn-outline-purple.disabled.focus,
.btn-outline-purple[disabled].focus,
fieldset[disabled] .btn-outline-purple.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-outline-purple .badge {
  color: #501cd8;
  background-color: #fff;
}
.btn-outline-sapphire {
  color: #303f9f;
  background-color: transparent;
  border-color: #303f9f;
}
.btn-outline-sapphire:focus,
.btn-outline-sapphire.focus {
  color: #303f9f;
  box-shadow: 0 0 0 0.2em rgba(48, 63, 159, 0.5);
}
.btn-outline-sapphire:hover,
.btn-outline-sapphire.hover {
  color: #fff;
  background-color: #212c6e;
  border-color: #212c6e;
}
.btn-outline-sapphire:active,
.btn-outline-sapphire.active,
.open > .dropdown-toggle.btn-outline-sapphire {
  color: #fff;
  background-color: #1a2356;
  border-color: #1a2356;
}
.btn-outline-sapphire:active,
.btn-outline-sapphire.active,
.open > .dropdown-toggle.btn-outline-sapphire {
  background-image: none;
}
.btn-outline-sapphire.disabled:hover,
.btn-outline-sapphire[disabled]:hover,
fieldset[disabled] .btn-outline-sapphire:hover,
.btn-outline-sapphire.disabled:focus,
.btn-outline-sapphire[disabled]:focus,
fieldset[disabled] .btn-outline-sapphire:focus,
.btn-outline-sapphire.disabled.focus,
.btn-outline-sapphire[disabled].focus,
fieldset[disabled] .btn-outline-sapphire.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-outline-sapphire .badge {
  color: #303f9f;
  background-color: #fff;
}
.btn-outline-pink {
  color: #f43c7b;
  background-color: transparent;
  border-color: #f43c7b;
}
.btn-outline-pink:focus,
.btn-outline-pink.focus {
  color: #f43c7b;
  box-shadow: 0 0 0 0.2em rgba(244, 60, 123, 0.5);
}
.btn-outline-pink:hover,
.btn-outline-pink.hover {
  color: #fff;
  background-color: #dc0c53;
  border-color: #dc0c53;
}
.btn-outline-pink:active,
.btn-outline-pink.active,
.open > .dropdown-toggle.btn-outline-pink {
  color: #fff;
  background-color: #bf0a48;
  border-color: #bf0a48;
}
.btn-outline-pink:active,
.btn-outline-pink.active,
.open > .dropdown-toggle.btn-outline-pink {
  background-image: none;
}
.btn-outline-pink.disabled:hover,
.btn-outline-pink[disabled]:hover,
fieldset[disabled] .btn-outline-pink:hover,
.btn-outline-pink.disabled:focus,
.btn-outline-pink[disabled]:focus,
fieldset[disabled] .btn-outline-pink:focus,
.btn-outline-pink.disabled.focus,
.btn-outline-pink[disabled].focus,
fieldset[disabled] .btn-outline-pink.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-outline-pink .badge {
  color: #f43c7b;
  background-color: #fff;
}
.btn-outline-light {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  color: #ffffff;
  box-shadow: 0 0 0 0.2em rgba(255, 255, 255, 0.5);
}
.btn-outline-light:hover,
.btn-outline-light.hover {
  color: #0566ff;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-light:active,
.btn-outline-light.active,
.open > .dropdown-toggle.btn-outline-light {
  color: #0566ff;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}
.btn-outline-light:active,
.btn-outline-light.active,
.open > .dropdown-toggle.btn-outline-light {
  background-image: none;
}
.btn-outline-light.disabled:hover,
.btn-outline-light[disabled]:hover,
fieldset[disabled] .btn-outline-light:hover,
.btn-outline-light.disabled:focus,
.btn-outline-light[disabled]:focus,
fieldset[disabled] .btn-outline-light:focus,
.btn-outline-light.disabled.focus,
.btn-outline-light[disabled].focus,
fieldset[disabled] .btn-outline-light.focus {
  background-color: rgba(136, 148, 171, 0.16);
  border-color: transparent;
  color: #8894ab;
  box-shadow: none;
}
.btn-outline-light .badge {
  color: #ffffff;
  background-color: #0566ff;
}
.btn-link {
  color: #0566ff;
  font-weight: normal;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #0566ff;
  text-decoration: none;
  background-color: transparent;
}
.btn-link:active,
.btn-link.active {
  color: #0047b7;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #647491;
  text-decoration: none;
}
.btn-link.btn-inline {
  padding: 0;
  vertical-align: initial;
  border: 0;
}
.btn-muted {
  color: #647491;
}
.btn-muted:hover {
  color: #4f5c73;
}
.btn-inline[class*='btn-size-'] {
  height: auto;
  font-size: inherit;
}
.btn-new {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-new span {
  display: inline-block;
}
.btn-new svg {
  fill: currentColor;
  margin-left: -2px;
  margin-right: 6px;
}
.btn-new.btn-link:not(.btn-link-button) {
  min-width: auto;
}
.btn-new.btn-default svg {
  fill: #080f1a;
}
.with-mouse .btn {
  box-shadow: none !important;
}
.btn-size-xl {
  padding: 0 20px;
  font-size: 18px;
  line-height: 23px;
  border-radius: 8px;
  min-width: 100px;
  height: 46px;
}
.btn-size-xl svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.btn-size-l {
  padding: 0 16px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px;
  min-width: 80px;
  height: 38px;
}
.btn-size-l svg {
  width: 20px;
  height: 20px;
}
.btn-size-m {
  padding: 0 14px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 8px;
  min-width: 64px;
  height: 34px;
}
.btn-size-m svg {
  width: 20px;
  height: 20px;
}
.btn-size-s {
  padding: 0 12px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  min-width: 56px;
  height: 22px;
}
.btn-size-s svg {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.btn + .btn {
  margin-left: 12px;
}
.btn.btn-size-s + .btn.btn-size-s {
  margin-left: 8px;
}
.btn-block {
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: auto;
}
.btn-new.btn-block {
  display: inline-flex;
}
.btn-hollow {
  background-color: transparent;
}
.btn-hollow.green {
  color: #080f1a;
  border-color: #64ed80;
  background-color: #64ed80;
  border-width: 2px;
  font-weight: 400;
}
.btn-hollow.green:hover,
.btn-hollow.green:focus {
  background-color: #31e756;
  border-color: #31e756;
}
.btn-hollow.green:focus,
.btn-hollow.green.focus {
  box-shadow: 0 0 0 0.2em rgba(49, 231, 86, 0.3);
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}
.btn:before {
  font-size: 17px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.btn.btn-new:before {
  top: 0;
}
.btn-size-s:before {
  font-size: 14px;
  margin-right: 8px;
  top: 0;
}
.btn-new.btn-link:hover span {
  text-decoration: underline;
}
a.btn-new.btn-link:hover {
  text-decoration: underline;
}
.btn.btn-loading {
  pointer-events: none;
  color: transparent;
  position: relative;
}
.btn.btn-loading:before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('../public/media/img/panel/loading-white.png');
  background-size: 100% 100%;
  animation: spinner-animation 1s linear infinite;
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}
.btn.btn-loading.btn-link:before,
.btn.btn-loading.btn-outline:before {
  background-image: url('../public/media/img/panel/loading.png');
}
.btn.btn-loading.btn-default:before,
.btn.btn-loading.btn-outline-default:before {
  background-image: url('../public/media/img/panel/loading-gray.png');
}
.btn.btn-loading.btn-size-s:before {
  width: 18px;
  height: 18px;
  top: calc(50% - 9px);
  left: calc(50% - 9px);
}
.btn.btn-loading.btn-size-m:before {
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}
.btn.btn-loading:hover,
.btn.btn-loading:focus,
.btn.btn-loading.focus {
  color: transparent;
}
.btn-new.btn-icon-after svg {
  order: 2;
  margin-right: 0;
  margin-left: 8px;
}
@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.input-group .form-control:focus {
  z-index: 3;
}
.input-group-addon,
.input-group .form-control {
  display: table-cell;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.input-group-addon {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}
.input-group-addon {
  padding: 6px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #080f1a;
  text-align: center;
  background-color: #f7f8fc;
  border: 1px solid #d3dbe5;
  border-radius: 8px;
}
.input-group-addon.input-sm {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
}
.input-group-addon input[type='radio'],
.input-group-addon input[type='checkbox'] {
  margin-top: 0;
}
.input-group .form-control:first-child,
.input-group-addon:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group-addon:first-child {
  border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group-addon:last-child {
  border-left: 0;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  opacity: 0.2;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}
button.close {
  outline: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.modal-open {
  overflow: hidden;
}
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal .with-backdrop {
  background: rgba(0, 0, 0, 0.8);
}
.modal .with-backdrop > .close {
  color: #fff;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: auto;
}
.modal-body {
  position: relative;
  padding: 10px 0 0 0;
}
.modal-clean {
  background: #fff;
  padding: 50px 70px;
  text-align: center;
  border-radius: 8px;
  margin-top: 110px;
  width: 630px;
}
.modal-clean h2 {
  margin-top: 0;
  font-size: 38px;
}
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}
.clearfix:after {
  clear: both;
}
.hide {
  display: none !important;
}
.ReactModal__Overlay {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  opacity: 0;
}
.ReactModal__Overlay .modal-dialog {
  outline: none;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.ReactModal__Overlay--after-open {
  overflow-y: auto;
  opacity: 1;
}
.ReactModal__Overlay--after-open .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Overlay--before-close .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}
#main {
  --surface-color: #fff;
  --label-hover-background: #dce9ff;
  --label-hover-color: #001b47;
  --surface-hover-color: #dce9ff;
  --min-panel-width: 1200px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#main.hidden {
  display: none;
}
#app-container {
  border-top: solid 4px #0566ff;
  min-width: var(--min-panel-width);
  flex: 1 1 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}
#app-container.mobile {
  min-width: auto;
}
#app-container.mobile #app-nav {
  display: none;
}
#app-container .reactivation {
  border-top-color: #161621;
}
@media (min-width: 1200px) {
  #app-container:not(.wide) #app-content-header h1 {
    max-width: calc(100% - 260px);
  }
  #app-container:not(.wide) #app-content-header h1.noButton span:not(.standardHeader) {
    max-width: 100%;
  }
}
@media (max-width: 1199px) {
  #app-container:not(.wide) #app-content-header h1 {
    max-width: calc(100% - 268px);
  }
  #app-container:not(.wide) #app-content-header h1.noButton span {
    max-width: calc(100% - 85px);
  }
}
#app-container .app-trial-expired #app-content-header {
  display: none;
}
#app-container .app-trial-expired #app-content {
  height: 100%;
  overflow-y: auto;
}
.loading {
  padding: 40px;
  display: flex;
  justify-content: center;
}
.loading.full {
  margin: 0;
  width: 100%;
  height: 100%;
  align-items: center;
}
.loading:before {
  content: '';
  display: block;
  width: 36px;
  height: 36px;
  background-image: url('../public/media/img/panel/loading-36.png');
  background-size: 100% 100%;
  animation: spinner-animation 1s linear infinite;
}
@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes swal2-my-show {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes swal2-my-hide {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}
.swal2-container .custom-sweet-alert {
  font-size: 16px;
  padding: 32px 32px 24px;
}
.swal2-container .custom-sweet-alert.swal2-show {
  animation: swal2-my-show 0.3s;
}
.swal2-container .custom-sweet-alert.swal2-hide {
  animation: swal2-my-hide 0.3s;
}
.swal2-container .custom-sweet-alert.wide {
  width: 500px !important;
  border-radius: 8px;
  color: #080f1a;
}
.swal2-container .custom-sweet-alert .swal2-icon {
  margin: 0 auto 12px 0;
  font-size: 6px;
  border-width: 3px;
  font-weight: bold;
}
.swal2-container .custom-sweet-alert .swal2-icon.swal2-info {
  color: #647491;
  border-color: #647491;
}
.swal2-container .custom-sweet-alert .swal2-actions {
  margin-top: 32px;
  width: 100%;
  justify-content: flex-end;
}
.swal2-container .custom-sweet-alert .swal2-title {
  text-align: left;
}
.swal2-container .custom-sweet-alert .swal2-html-container {
  padding: 0;
  margin: 12px 0;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
}
.swal2-container .custom-sweet-alert button.swal2-styled {
  font-weight: normal;
  padding: 9px 16px;
  border-radius: 8px;
}
.swal2-container .custom-sweet-alert button.swal2-styled.swal2-cancel {
  color: #080f1a;
  background: #ffffff;
  border: 1px solid #d1d9e0;
}
.swal2-container .custom-sweet-alert h2 {
  font-size: 20px;
  line-height: 26px;
  margin: 0;
  padding: 0;
}
.swal2-container .custom-sweet-alert hr {
  margin: 0;
  height: 20px;
}
.swal2-container .custom-sweet-alert p {
  margin-bottom: 10px;
}
.swal2-container .custom-sweet-alert button.confirm {
  margin-bottom: 10px;
}
.swal2-container .custom-sweet-alert .swal2-close {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 36px;
  height: 36px;
  box-shadow: 0 8px 20px rgba(0, 27, 71, 0.24);
  border-radius: 50%;
  background: #ffffff;
  font-size: 26px;
  color: #647491;
}
body.hidden-overflow {
  overflow: hidden;
}
body button,
body input,
body optgroup,
body select,
body textarea {
  line-height: inherit;
}
body:not(.simulate-visitor) > #tidio-chat iframe {
  right: 10px !important;
}
.swal2-modal .swal2-footer {
  border-top: none;
  color: #999999;
}
[class^='videoask-embed__modal_wrapper'],
[class^='videoask-embed__button'] {
  left: 80px !important;
  z-index: 100 !important;
}
.swal2-container {
  z-index: 1000000 !important;
}
