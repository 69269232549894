//
// Typography
// --------------------------------------------------

// Headings
// -------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: @headings-font-family;
    font-weight: @headings-font-weight;
    line-height: @headings-line-height;
    color: @headings-color;

    small,
    .small {
        font-weight: normal;
        line-height: 1;
        color: @headings-small-color;
    }
    &:before {
        margin-right: 10px;
        color: @primary-blue-500;
    }
}

h1,
h2,
h3 {
    margin-top: @line-height-computed;
    margin-bottom: (@line-height-computed / 2);

    small,
    .small {
        font-size: 65%;
    }
}
h4,
h5,
h6 {
    margin-top: (@line-height-computed / 2);
    margin-bottom: (@line-height-computed / 2);

    small,
    .small {
        font-size: 75%;
    }
}

h1 {
    font-size: @font-size-h1;
}
h2 {
    font-size: @font-size-h2;
}
h3 {
    font-size: @font-size-h3;
}
h4 {
    font-size: @font-size-h4;
}

// Body text
// -------------------------

p {
    margin: 0 0 (@line-height-computed / 2);
}

// Emphasis & misc
// -------------------------

// Ex: (12px small font / 14px base font) * 100% = about 85%
small,
.small {
    font-size: floor((100% * @font-size-small / @font-size-base));
}

// Alignment
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}

// Contextual colors

.text-danger {
    color: @state-danger-text;
    a&:hover,
    a&:focus {
        color: darken(@state-danger-text, 10%);
    }
}

// Lists
// -------------------------

// Unordered and Ordered lists
ul,
ol {
    margin-top: 0;
    margin-bottom: (@line-height-computed / 2);
    ul,
    ol {
        margin-bottom: 0;
    }
}
