//
// Utility classes
// --------------------------------------------------

.clearfix {
    .clearfix();
}

.hide {
    display: none !important;
}
