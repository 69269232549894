//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
    display: inline-block;
    margin-bottom: 0; // For input.btn
    font-weight: @btn-font-weight;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    white-space: nowrap;
    min-width: 64px;
    .button-size(
        @padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base;
            @btn-border-radius-base
    );
    .user-select(none);

    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
            outline: none;
        }
    }

    &:hover,
    &:focus,
    &.focus {
        color: @btn-default-color;
        text-decoration: none;
    }

    &:active,
    &.active {
        outline: 0;
        background-image: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: @cursor-disabled;
        .box-shadow(none);
        color: @gray-300;
        border-color: transparent;
        &:not(.btn-loading):not(.btn-link) {
            background-color: @gray-100;
        }
    }

    a& {
        &.disabled,
        fieldset[disabled] & {
            pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
        }
    }
}

// Alternate buttons
// --------------------------------------------------
.btn-default {
    .button-variant(
        @btn-default-color; rgba(136, 148, 171, 0%); @btn-default-border; @gray-100; @gray-300);
    &:focus,
    &.focus {
        box-shadow: 0 0 0 0.2em fade(#b3c0cb, 30);
    }
    &:active,
    &.active,
    .open > .dropdown-toggle& {
        color: @btn-default-color;
        background-color: rgba(136, 148, 171, 16%);
        border-color: darken(#bfc4cf, 6%);
    }
    .badge {
        color: #fff;
    }
}

.btn-primary {
    .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border; @primary-blue-600; @primary-blue-600);
}

// Success appears as green
.btn-success {
    .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border; @green-600; @green-600);
}

// Warning appears as orange
.btn-warning {
    .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border; @orange-500; @orange-500);
}

// Danger and error appear as red
.btn-danger {
    .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border; @red-600; @red-600);
}

.btn-purple {
    .button-variant(@btn-primary-color; @purple-700; @purple-700; @purple-800; @purple-800);
}

.btn-sapphire {
    .button-variant(@btn-primary-color; @sapphire-700; @sapphire-700; @sapphire-800; @sapphire-800);
}

.btn-pink {
    .button-variant(@btn-primary-color; @pink-400; @pink-400; @pink-500; @pink-500);
}

.btn-trout {
    .button-variant(@btn-primary-color; @gray-500; @gray-500; @oxford-blue; @oxford-blue);
}

.btn-sky {
    .button-variant(@btn-primary-color; @azure-400; @azure-400; @azure-500; @azure-500);
}

.btn-shade {
    .button-variant(@primary-blue-600; @primary-blue-100; @primary-blue-100; @primary-blue-200; @primary-blue-200);
}

.btn-white {
    .button-variant(@primary-blue-500, #ffffff, #ffffff, @gray-100, @gray-100);
}

.btn-orange {
    .button-variant(@btn-primary-color, @orange-400, @orange-400, @orange-500, @orange-500);
}

.btn-disabled {
    .button-variant(@gray-500, #eceef1, #eceef1, #eceef1, #eceef1);
}

.btn-upgrade {
    .button-variant(#080f1a; #64ed80; #64ed80; #31e756; #31e756);
}

// Outline buttons
// -------------------------
.btn-outline-default {
    .button-outline-variant(
        @btn-default-color; @gray-100; @btn-default-border; rgba(136, 148, 171, 8%) ;
            rgba(136, 148, 171, 56%)
    );
    color: @btn-default-color;
    &:focus,
    &.focus {
        color: @btn-default-color;
    }
}

.btn-outline-primary {
    .button-outline-variant(
        @btn-primary-color; @btn-primary-bg; @btn-primary-border; @primary-blue-600; @primary-blue-600
    );
}

.btn-outline-success {
    .button-outline-variant(
        @btn-success-color; @btn-success-bg; @btn-success-border; @green-600; @green-600
    );
}

.btn-outline-warning {
    .button-outline-variant(
        @btn-warning-color; @btn-warning-bg; @btn-warning-border; @orange-500; @orange-500
    );
}

.btn-outline-danger {
    .button-outline-variant(
        @btn-danger-color; @btn-danger-bg; @btn-danger-border; @red-600; @red-600
    );
}

.btn-outline-purple {
    .button-outline-variant(@btn-primary-color; @purple-700; @purple-700; @purple-800; @purple-800);
}

.btn-outline-sapphire {
    .button-outline-variant(@btn-primary-color; @sapphire-700; @sapphire-700; @sapphire-800; @sapphire-800);
}

.btn-outline-pink {
    .button-outline-variant(@btn-primary-color; @pink-400; @pink-400; @pink-500; @pink-500);
}

.btn-outline-light {
    .button-outline-variant(@primary-blue-500; @white; @white; @white; @white);
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
    color: @link-color;
    font-weight: normal;
    border-radius: 0;

    &,
    &:active,
    &.active,
    &[disabled],
    fieldset[disabled] & {
        background-color: transparent;
        .box-shadow(none);
    }
    &,
    &:hover,
    &:focus,
    &:active {
        border-color: transparent;
    }
    &:hover,
    &:focus {
        color: @link-color;
        text-decoration: none;
        background-color: transparent;
    }
    &:active,
    &.active {
        color: darken(@link-color, 15%);
    }
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus {
            color: @btn-link-disabled-color;
            text-decoration: none;
        }
    }
    &.btn-inline {
        padding: 0;
        vertical-align: initial;
        border: 0;
    }
}

.btn-muted {
    color: @gray-500;
    &:hover {
        color: darken(@gray-500, 10%);
    }
}

.btn-inline[class*='btn-size-'] {
    height: auto;
    font-size: inherit;
}

// Button Sizes
// --------------------------------------------------

.btn-new {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    span {
        display: inline-block;
    }
    svg {
        fill: currentColor;
        margin-left: -2px;
        margin-right: 6px;
    }
    &.btn-link:not(.btn-link-button) {
        min-width: auto;
    }
}

.btn-new.btn-default {
    svg {
        fill: @black;
    }
}

.with-mouse .btn {
    box-shadow: none !important;
}

.btn-size-xl {
    .button-size(0; 20px; @font-size-UI18; @line-height-UI18; 8px);
    min-width: 100px;
    height: 46px;
    svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
}

.btn-size-l {
    .button-size(0; 16px; @font-size-UI16; @line-height-UI16; 8px);
    min-width: 80px;
    height: 38px;
    svg {
        width: 20px;
        height: 20px;
    }
}

.btn-size-m {
    .button-size(0; 14px; @font-size-UI14; @line-height-UI14; 8px);
    min-width: 64px;
    height: 34px;
    svg {
        width: 20px;
        height: 20px;
    }
}

.btn-size-s {
    .button-size(0; 12px; @font-size-UI12; @line-height-UI12; 8px);
    min-width: 56px;
    height: 22px;
    svg {
        width: 14px;
        height: 14px;
        margin-right: 4px;
    }
}

.btn + .btn {
    margin-left: 12px;
}
.btn.btn-size-s + .btn.btn-size-s {
    margin-left: 8px;
}

// Block button
// --------------------------------------------------

.btn-block {
    display: block;
    width: 100%;
    max-width: 100%;
    min-width: auto;
}
.btn-new.btn-block {
    display: inline-flex;
}

.btn-hollow {
    background-color: transparent;
    &.green {
        color: @black;
        border-color: #64ed80;
        background-color: #64ed80;
        border-width: 2px;
        font-weight: 400;
        &:hover,
        &:focus {
            background-color: #31e756;
            border-color: #31e756;
        }
        &:focus,
        &.focus {
            box-shadow: 0 0 0 0.2em fade(#31e756, 30);
        }
    }
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
    margin-top: 5px;
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
    &.btn-block {
        width: 100%;
    }
}

// Icons in buttons
.btn:before {
    font-size: 17px;
    margin-right: 10px;
    position: relative;
    top: 2px;
}

.btn.btn-new:before {
    top: 0;
}

.btn-size-s:before {
    font-size: @font-size-UI14;
    margin-right: 8px;
    top: 0;
}

.btn-new.btn-link:hover {
    span {
        text-decoration: underline;
    }
}
a.btn-new.btn-link:hover {
    text-decoration: underline;
}

// Loading in buttons
.btn.btn-loading {
    pointer-events: none;
    color: transparent;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-image: url('../../public/media/img/panel/loading-white.png');
        background-size: 100% 100%;
        animation: spinner-animation 1s linear infinite;
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
    }
    &.btn-link:before,
    &.btn-outline:before {
        background-image: url('../../public/media/img/panel/loading.png');
    }
    &.btn-default:before,
    &.btn-outline-default:before {
        background-image: url('../../public/media/img/panel/loading-gray.png');
    }
    &.btn-size-s:before {
        width: 18px;
        height: 18px;
        top: calc(50% - 9px);
        left: calc(50% - 9px);
    }

    &.btn-size-m:before {
        width: 20px;
        height: 20px;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
    }

    &:hover,
    &:focus,
    &.focus {
        color: transparent;
    }
}

.btn-new.btn-icon-after {
    svg {
        order: 2;
        margin-right: 0;
        margin-left: 8px;
    }
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
