//
// Forms
// --------------------------------------------------

// Normalize non-controls
//
// Restyle and baseline non-control form elements.

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    // Chrome and Firefox set a `min-width: min-content;` on fieldsets,
    // so we reset that to ensure it behaves more like a standard block element.
    // See https://github.com/twbs/bootstrap/issues/12359.
    min-width: 0;
}

label {
    display: inline-block;
    max-width: 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
    font-weight: 400;
}

// Normalize form controls
//
// While most of our form styles require extra classes, some basic normalization
// is required to ensure optimum display with or without those classes to better
// address browser inconsistencies.

// Override content-box in Normalize (* isn't specific enough)
input[type='search'] {
    .box-sizing(border-box);
}

// Position radios and checkboxes better
input[type='radio'],
input[type='checkbox'] {
    margin: 4px 0 0;
    margin-top: 1px \9; // IE8-9
    line-height: normal;
}

input[type='file'] {
    display: block;
}

// Make range inputs behave like textual form controls
input[type='range'] {
    display: block;
    width: 100%;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
    height: auto;
}

// Focus for file, radio, and checkbox
input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
    .tab-focus();
}

// Common form controls
//
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
//
// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]

.form-control {
    display: block;
    width: 100%;
    height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    padding: 0px @padding-base-horizontal;
    margin-bottom: 20px;
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @input-color;
    background-color: @input-bg;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 2px solid @input-border;
    border-radius: @input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.

    // Placeholder
    .placeholder();

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &[disabled],
    &[readonly],
    fieldset[disabled] & {
        background-color: @input-bg-disabled;
        opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
    }

    &[disabled],
    fieldset[disabled] & {
        cursor: @cursor-disabled;
    }

    // Reset height for `textarea`s
    textarea& {
        height: auto;
        padding: 8px 16px;
    }
}

// Search inputs in iOS
//
// This overrides the extra rounded corners on search inputs in iOS so that our
// `.form-control` class can properly style them. Note that this cannot simply
// be added to `.form-control` as it's not specific enough. For details, see
// https://github.com/twbs/bootstrap/issues/11586.

input[type='search'] {
    -webkit-appearance: none;
}

// Special styles for iOS temporal inputs
//
// In Mobile Safari, setting `display: block` on temporal inputs causes the
// text within the input to become vertically misaligned. As a workaround, we
// set a pixel line-height that matches the given height of the input, but only
// for Safari. See https://bugs.webkit.org/show_bug.cgi?id=139848
//
// Note that as of 8.3, iOS doesn't support `datetime` or `week`.

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type='date'],
    input[type='time'],
    input[type='datetime-local'],
    input[type='month'] {
        &.form-control {
            line-height: @input-height-base;
        }

        &.input-sm {
            line-height: @input-height-small;
        }
    }
}

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
    margin-bottom: @form-group-margin-bottom;
    .form-control {
        margin-bottom: 0;
    }
}

// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.radio,
.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;

    label {
        min-height: @line-height-computed; // Ensure the input doesn't jump when there is no text
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: normal;
        cursor: pointer;
    }
}

.radio input[type='radio'],
.checkbox input[type='checkbox'] {
    position: absolute;
    margin-left: -20px;
    margin-top: 4px \9;
}

.radio + .radio,
.checkbox + .checkbox {
    margin-top: -5px; // Move up sibling radios or checkboxes for tighter spacing
}

// Apply same disabled cursor tweak as for inputs
// Some special care is needed because <label>s don't inherit their parent's `cursor`.
//
// Note: Neither radios nor checkboxes can be readonly.
input[type='radio'],
input[type='checkbox'] {
    &[disabled],
    &.disabled,
    fieldset[disabled] & {
        cursor: @cursor-disabled;
    }
}

// These classes are used on elements with <label> descendants
.radio,
.checkbox {
    &.disabled,
    fieldset[disabled] & {
        label {
            cursor: @cursor-disabled;
        }
    }
}

// Help text
//
// Apply to any element you wish to create light text for placement immediately
// below a form control. Use for general help, formatting, or instructional text.

.help-block {
    display: block; // account for any element using help-block
    margin-top: 5px;
    margin-bottom: 10px;
    color: lighten(@text-color, 25%); // lighten the text some for contrast
}
