@import '../../shared/components/design';
//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal

// Kill the scroll on the body
.modal-open {
    overflow: hidden;
}

// Container that the modal scrolls within
.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: @zindex-modal;
    -webkit-overflow-scrolling: touch;

    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;

    // When fading in the modal, animate it to slide down
    &.fade .modal-dialog {
        .translate(0, -25%);
        .transition-transform(~'0.3s ease-out');
    }
    &.in .modal-dialog {
        .translate(0, 0);
    }

    .with-backdrop {
        background: rgba(0, 0, 0, 0.8);
        & > .close {
            color: #fff;
        }
    }
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    width: auto;
    margin: auto;
}

// Modal body
.modal-body {
    position: relative;
    padding: @modal-inner-padding;
}

.modal-clean {
    background: #fff;
    padding: 50px 70px;
    text-align: center;
    border-radius: 8px;
    margin-top: 110px;
    width: 630px;
    h2 {
        margin-top: 0;
        font-size: 38px;
    }
}
