// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(@color; @background; @border; @background-hover; @border-hover) {
    color: @color;
    background-color: @background;
    border-color: @border;

    &:focus,
    &.focus {
        color: @color;
        box-shadow: 0 0 0 0.2em fade(@background, 50%);
    }
    &:hover,
    &.hover {
        color: @color;
        background-color: @background-hover;
        border-color: @border-hover;
    }
    &:active,
    &.active,
    .open > .dropdown-toggle& {
        color: @color;
        background-color: darken(@background-hover, 6%);
        border-color: darken(@border-hover, 6%);
    }
    &:active,
    &.active,
    .open > .dropdown-toggle& {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            background-color: rgba(136, 148, 171, 16%);
            border-color: transparent;
            color: rgb(136, 148, 171);
            box-shadow: none;
        }
    }

    .badge {
        color: @background;
        background-color: @color;
    }
}

.button-outline-variant(@color; @background; @border; @background-hover; @border-hover) {
    color: @background;
    background-color: transparent;
    border-color: @border;

    &:focus,
    &.focus {
        color: @background;
        box-shadow: 0 0 0 0.2em fade(@border, 50%);
    }
    &:hover,
    &.hover {
        color: @color;
        background-color: @background-hover;
        border-color: @border-hover;
    }
    &:active,
    &.active,
    .open > .dropdown-toggle& {
        color: @color;
        background-color: darken(@background-hover, 6%);
        border-color: darken(@border-hover, 6%);
    }
    &:active,
    &.active,
    .open > .dropdown-toggle& {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            background-color: rgba(136, 148, 171, 16%);
            border-color: transparent;
            color: rgb(136, 148, 171);
            box-shadow: none;
        }
    }

    .badge {
        color: @background;
        background-color: @color;
    }
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
    padding: @padding-vertical @padding-horizontal;
    font-size: @font-size;
    line-height: @line-height;
    border-radius: @border-radius;
}
