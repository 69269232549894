@import '../design';
.tippy-box img,
.tippy-box picture,
.tippy-box video {
    max-width: revert;
}
.tippy-box[data-theme~='default'] {
    background: @white;
    color: @black;
    font-size: @font-size-UI14;
    line-height: @line-height-UI14;
    box-shadow: 0 8px 20px rgba(0, 27, 71, 0.24);
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
    overflow-wrap: break-word;
    .tippy-content {
        padding: 5px 8px;
    }
    .tippy-arrow {
        color: @white;
    }
}

.tippy-box[data-theme~='translate-arrow'] {
    .tippy-arrow:before {
        transform: translateX(-15px);
    }
}
.tippy-box[data-theme~='hide'] {
    display: none;
}

.tippy-box[data-theme~='default'][data-theme~='blue'] {
    background-color: @primary-blue-500;
    color: @white;
    font-size: @font-size-UI14;
    line-height: @line-height-UI14;
    &[data-animatefill] {
        background-color: transparent;
    }
    .tippy-backdrop {
        background-color: @primary-blue-500;
    }
}

.tippy-box[data-theme~='trial-expired'] {
    font-size: @font-size-UI14;
    line-height: @line-height-UI14;
    padding: 12px 13px 2px 13px;
    background-color: fade(@gray-800, 95);
    width: 247px;
    text-align: left;
    color: @white;
    .tippy-content {
        padding: 0;
    }
    .tippy-arrow:before {
        border-left-color: fade(@gray-800, 95);
        border-right-color: fade(@gray-800, 95);
    }
    .header {
        display: block;
    }
    .subheader {
        margin: 0;
    }
}
.tippy-box[data-theme~='business'] {
    margin-left: 15px;
}
.tippy-box[data-theme~='limited'] {
    margin-right: 60px;
}
@media (min-width: 1920px) {
    .tippy-box[data-theme~='business'] {
        margin-left: 30px;
    }
    .tippy-box[data-theme~='limited'] {
        margin-right: 80px;
    }
}
.tippy-box[data-theme~='error'] {
    font-size: 13px;
    background-color: @red-500;
    max-width: 200px;
    text-align: center;
    margin-bottom: 10px;
    color: @white;
    .tippy-content {
        padding: 5px 9px;
    }
    &[data-placement^='bottom'] .tippy-arrow:before {
        border-bottom-color: @red-500;
    }
    &[data-placement^='top'] .tippy-arrow:before {
        border-top-color: @red-500;
    }
    &[data-placement^='left'] .tippy-arrow:before {
        border-left-color: @red-500;
    }
    &[data-placement^='right'] .tippy-arrow:before {
        border-right-color: @red-500;
    }
}

@media (min-width: 1199px) {
    .tippy-box[data-theme~='hideOnBigScreen'] {
        display: none;
    }
}
.tippy-box[data-theme~='dropdown'] {
    background-color: @white;
    border-radius: 8px;
    box-shadow: 0 8px 24px 0 @oxford-blue32;
    color: @black;
    .tippy-content {
        padding: 8px;
        overflow-y: auto;
    }
}
.tippy-box[data-theme~='trigger-chart'] {
    text-align: center;
    width: 120px;
}
.tippy-box[data-theme~='multiline'] {
    .tippy-content {
        text-align: left;
        padding: 20px;
    }
}
.tippy-box[data-theme~='template'] {
    width: 280px;
    .tippy-content {
        text-align: left;
        padding: 0;
        font-size: @font-size-UI14;
        line-height: @line-height-UI14;
        header {
            font-size: @font-size-UI18;
            line-height: @line-height-UI18;
            font-weight: bold;
            margin: 0;
        }
        p {
            margin-top: 12px;
        }
    }
}
.tippy-box[data-theme~='nav-template'] {
    width: 256px;
    .tippy-content {
        text-align: left;
        padding: 0;
    }
}

.tippy-box[data-theme~='nav-template-clean'] {
    border-radius: 8px;
    background: @white;
    color: @black;
    .tippy-arrow {
        color: @white;
    }
    .tippy-content {
        text-align: left;
        padding: 0;
    }
}

.tippy-box[data-theme~='nav-template-clean-black'] {
    border-radius: 4px;
    background: @gray-950;
    color: @white;
    .tippy-arrow {
        color: @gray-950;
    }
    .tippy-content {
        text-align: left;
        padding: 0;
    }
}

.tippy-box[data-theme~='default-flow-added'] {
    .tippy-content {
        text-align: left;
        padding: 0;
    }
    .tippy-arrow::before {
        transform: translateX(-100px);
    }
}

.tippy-box[data-theme~='nudge'] {
    margin-bottom: 0;
    border-radius: 8px;
}

.tippy-box[data-theme~='strategies-bonus-granted'],
.tippy-box[data-theme~='cart-preview'],
.tippy-box[data-theme~='orders-directory'],
.tippy-box[data-theme~='product-directory'] {
    background: white;
    color: @black;
    font-size: @font-size-UI14;
    text-align: left;
    width: 235px;
    border-radius: 8px;
    box-shadow: 0 12px 32px rgba(136, 148, 171, 0.24);
    &[data-placement^='bottom'] .tippy-arrow:before {
        border-bottom-color: @white;
    }
    &[data-placement^='top'] .tippy-arrow:before {
        border-top-color: @white;
    }
    &[data-placement^='left'] .tippy-arrow:before {
        border-left-color: @white;
    }
    &[data-placement^='right'] .tippy-arrow:before {
        border-right-color: @white;
    }
    .tippy-content {
        padding: 0;
    }
    @media (max-width: 1200px) {
        display: none;
    }
}
.tippy-box[data-theme~='cart-preview'] {
    padding: 16px 24px 18px;
}
.tippy-box[data-theme~='strategies-bonus-granted'] {
    padding: 20px 24px 16px;
}
.tippy-box[data-theme~='orders-directory'] {
    padding: 24px 24px 18px;
}
.tippy-box[data-theme~='product-directory'] {
    padding: 24px 24px 18px;
}

.tippy-box[data-theme~='creator-mode-switch'] {
    background: white;
    color: @black;
    font-size: @font-size-UI14;
    text-align: left;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 12px 32px rgba(0, 27, 71, 0.12);
    &[data-placement^='top'] .tippy-arrow {
        width: 26px;
        height: 20px;
        &:before {
            border-top-color: @white;
            bottom: -7px;
            border-width: 10px 13px 0;
        }
    }
    .tippy-content {
        padding: 0;
    }
}
