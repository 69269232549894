//
// Tables
// --------------------------------------------------
table {
    background-color: transparent;
}

th {
    text-align: left;
    font-weight: 400;
    color: @gray-500;
}

// Baseline styles
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 6px;
    border-collapse: collapse;

    // Cells
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                min-width: 55px;
                vertical-align: middle;
                padding: 8px 16px;
                line-height: @line-height-base;
            }
            > th {
                height: 48px;
            }
            > td {
                height: 56px;
            }
            &:first-of-type td {
                border-top: none;
            }
        }
    }
    // Remove top border from thead by default
    > caption + thead,
    > colgroup + thead,
    > thead:first-child {
        > tr:first-child {
            > th,
            > td {
                border-top: 0;
            }
        }
    }
}

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
    overflow-x: auto;
}
