@import 'bootstrap/variables.less';
@import '../../webApp/shared/components/design.less';

.ReactModal__Overlay {
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
    opacity: 0;
    .modal-dialog {
        outline: none;
        .translate(0, -25%);
        .transition-transform(~'0.3s ease-out');
    }
    &--after-open {
        overflow-y: auto;
        opacity: 1;
        .modal-dialog {
            .translate(0, 0);
        }
    }
    &--before-close {
        opacity: 0;
        .modal-dialog {
            .translate(0, -25%);
        }
    }
}

#main {
    --surface-color: @body-bg;
    --label-hover-background: @primary-blue-100;
    --label-hover-color: @primary-blue-900;
    --surface-hover-color: @primary-blue-100;
    --min-panel-width: 1200px;
    display: flex;
    flex-direction: column;
    height: 100vh;

    &.hidden {
        display: none;
    }
}

#app-container {
    border-top: solid 4px @primary-blue-500;
    min-width: var(--min-panel-width);
    flex: 1 1 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    &.mobile {
        min-width: auto;
        #app-nav {
            display: none;
        }
    }
    .reactivation {
        border-top-color: #161621;
    }
    &:not(.wide) {
        // Only for visitor view
        @media (min-width: @screen-lg-min) {
            #app-content-header {
                h1 {
                    max-width: ~'calc(100% - 260px)';
                    &.noButton {
                        span:not(.standardHeader) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
        @media (max-width: @screen-md-max) {
            #app-content-header {
                h1 {
                    max-width: ~'calc(100% - 268px)';
                    &.noButton {
                        span {
                            max-width: ~'calc(100% - 85px)';
                        }
                    }
                }
            }
        }
    }
    .app-trial-expired {
        #app-content-header {
            display: none;
        }
        #app-content {
            height: 100%;
            overflow-y: auto;
        }
    }
}

//Loading elements
.loading {
    padding: 40px;
    display: flex;
    justify-content: center;
    &.full {
        margin: 0;
        width: 100%;
        height: 100%;
        align-items: center;
    }
    &:before {
        content: '';
        display: block;
        width: 36px;
        height: 36px;
        background-image: url('../public/media/img/panel/loading-36.png');
        background-size: 100% 100%;
        animation: spinner-animation 1s linear infinite;
    }
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes swal2-my-show {
    0% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes swal2-my-hide {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
}

.swal2-container .custom-sweet-alert {
    font-size: @font-size-UI16;
    padding: 32px 32px 24px;
    &.swal2-show {
        animation: swal2-my-show 0.3s;
    }
    &.swal2-hide {
        animation: swal2-my-hide 0.3s;
    }
    &.wide {
        width: 500px !important;
        border-radius: 8px;
        color: @black;
    }
    .swal2-icon {
        margin: 0 auto 12px 0;
        font-size: 6px;
        border-width: 3px;
        font-weight: bold;
        &.swal2-info {
            color: @gray-500;
            border-color: @gray-500;
        }
    }
    .swal2-actions {
        margin-top: 32px;
        width: 100%;
        justify-content: flex-end;
    }
    .swal2-title {
        text-align: left;
    }
    .swal2-html-container {
        padding: 0;
        margin: 12px 0;
        font-size: @font-size-UI16;
        line-height: @line-height-UI16;
        text-align: left;
    }
    button.swal2-styled {
        font-weight: normal;
        padding: 9px 16px;
        border-radius: 8px;
        &.swal2-cancel {
            color: @black;
            background: @white;
            border: 1px solid @btn-default-border;
        }
    }
    h2 {
        font-size: @font-size-H20;
        line-height: @line-height-H20;
        margin: 0;
        padding: 0;
    }
    hr {
        margin: 0;
        height: 20px;
    }
    p {
        margin-bottom: 10px;
    }
    button.confirm {
        margin-bottom: 10px;
    }
    .swal2-close {
        position: absolute;
        top: -12px;
        right: -12px;
        width: 36px;
        height: 36px;
        box-shadow: 0 8px 20px rgba(0, 27, 71, 0.24);
        border-radius: 50%;
        background: @white;
        font-size: 26px;
        color: @gray-500;
    }
}

body {
    &.hidden-overflow {
        overflow: hidden;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
        line-height: inherit; // overwrite some globals
    }
    &:not(.simulate-visitor) > #tidio-chat iframe {
        right: 10px !important;
    }
}

.swal2-modal .swal2-footer {
    border-top: none;
    color: #999999;
}

[class^='videoask-embed__modal_wrapper'],
[class^='videoask-embed__button'] {
    left: 80px !important;
    z-index: 100 !important;
}

.swal2-container {
    z-index: 1000000 !important;
}
