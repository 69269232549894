//
// Input groups
// --------------------------------------------------

// Base styles
// -------------------------
.input-group {
    position: relative; // For dropdowns
    display: table;
    border-collapse: separate; // prevent input groups from inheriting border styles from table cells when placed within a table

    .form-control {
        // Ensure that the input is always above the *appended* addon button for
        // proper border colors.
        position: relative;
        z-index: 2;

        // IE9 fubars the placeholder attribute in text inputs and the arrows on
        // select elements in input groups. To fix it, we float the input. Details:
        // https://github.com/twbs/bootstrap/issues/11561#issuecomment-28936855
        float: left;

        width: 100%;
        margin-bottom: 0;

        &:focus {
            z-index: 3;
        }
    }
}

// Sizing options
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

// Display as table-cell
// -------------------------
.input-group-addon,
.input-group .form-control {
    display: table-cell;

    &:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
}
// Addon and addon wrapper for buttons
.input-group-addon {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle; // Match the inputs
}

// Text input groups
// -------------------------
.input-group-addon {
    padding: @padding-base-vertical @padding-base-horizontal;
    font-size: @font-size-base;
    font-weight: normal;
    line-height: 1;
    color: @input-color;
    text-align: center;
    background-color: @input-group-addon-bg;
    border: 1px solid @input-group-addon-border-color;
    border-radius: @input-border-radius;

    // Sizing
    &.input-sm {
        padding: @padding-small-vertical @padding-small-horizontal;
        font-size: @font-size-small;
        border-radius: @input-border-radius-small;
    }

    // Nuke default margins from checkboxes and radios to vertically center within.
    input[type='radio'],
    input[type='checkbox'] {
        margin-top: 0;
    }
}

// Reset rounded corners
.input-group .form-control:first-child,
.input-group-addon:first-child {
    .border-right-radius(0);
}
.input-group-addon:first-child {
    border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child {
    .border-left-radius(0);
}
.input-group-addon:last-child {
    border-left: 0;
}
